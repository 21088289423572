import * as service from "./service";
import notification from '../../components/notification';
import {capitalize, generateLabels} from "../../helpers";
import { history } from '../../store';


export default {
    state: {
      allOrganisation:{},
      organisation:{}
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, { data = {} }) {
            notification("error", capitalize(data.message));
            return {
                ...state,
                loading: false
            };
        },
        onCreateOrganisationSuccess(state, data) {
          notification("success", generateLabels("TOASTERS_ORGANISATION_CREATE"));
          history.push("/organisations/list")
          return {
            ...state, 
            loading: false,
          }
        },
        onGetAllOrganisationSuccess(state, data) {
            return {
              ...state,
              loading: false,
              allOrganisation: data,
            }
        },
        onUpdateOrganisationSuccess(state, data) {
          notification("success", generateLabels("TOASTERS_ORGANISATION_UPDATE"));
          history.push("/organisations/list")
          return {
            ...state, 
            loading: false,
          }
        },
        onGetOrganisationDetailsSuccess(state, data) {
          return {
            ...state,
            loading: false,
            organisation: data,
          }
      },
      onDeleteOrganisationSuccess(state, data) {
        notification("success", generateLabels("TOASTERS_ORGANISATION_DELETE"));
        return {
          ...state, 
          loading: false,
        }
      },
    },
    effects: {
      async createOrganisation(payload, rootState) {
        this.onRequest()
        try{
          let res = await service.createOrganisation(payload)
          this.onCreateOrganisationSuccess(res)
          return res
        }
        catch(e) {
          this.onError(e)
        }
      },
        async getAllOrganisation(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.getAllOrganisation(payload)
              this.onGetAllOrganisationSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async updateOrganisation(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.updateOrganisation(payload)
              this.onUpdateOrganisationSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },
          async organisationDetails(payload, rootState) {
            this.onRequest()
            try{
              let res = await service.organisationDetails(payload)
              this.onGetOrganisationDetailsSuccess(res)
              return res
            }
            catch(e) {
              this.onError(e)
            }
          },    
          async deleteOrganisation(payload, rootState) {
            this.onRequest();
            try {
              let res = await service.deleteOrganisation(payload);
              this.onDeleteOrganisationSuccess(res);
              return res;
            } catch (e) {
              this.onError(e);
            }
          },
    }
}