import { api, catchHandler } from "../../helpers/axios";
import config from "../../../config/index";
import { objToUrlParams} from "../../helpers";

export function createOrganisation(data) {
  return api()
    .post(config.routes.organisation,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function getAllOrganisation(data) {
    return api()
      .get(`${config.routes.organisations}?${objToUrlParams(data)}`)
      .then(res => res.data)
      .catch(catchHandler)
}
export function updateOrganisation(data) {
  return api()
    .put(config.routes.organisation,data)
    .then(res => res.data)  
    .catch(catchHandler);
}
export function organisationDetails(data) {
  return api()
    .get(`${config.routes.organisation}?${objToUrlParams(data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteOrganisation(data) {
  return api()
    .delete(config.routes.organisation, {data: data})
    .then(res => res.data)
    .catch(catchHandler)
}